const otcme = {
  images: [
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC.ME%2F3.png?alt=media&token=5e917251-a45f-4f8c-b4f3-1406d7c84c53",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC.ME%2F1.png?alt=media&token=c4c6abc8-63a0-45d4-bcac-cf5288cef840",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC.ME%2F2.png?alt=media&token=19a326c6-405c-4eb4-bdcc-9585ca765f97",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC.ME%2F4.png?alt=media&token=e9e08285-1d84-4f9d-a1bd-dace79cd81f0",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC.ME%2F5.png?alt=media&token=95aae483-9691-4df1-85bf-f1ae05473315",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC.ME%2F6.png?alt=media&token=43ebb772-a371-4c06-a905-b9318a8efd74",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC.ME%2F7.png?alt=media&token=490695d8-d0ab-40f1-a22c-94642b49e68b",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC.ME%2F8.png?alt=media&token=3b781d7f-b902-4245-91e3-0db36e0ae1c0",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC.ME%2F9.png?alt=media&token=887f35f7-94cf-4a5c-8206-db4b2612f9f0",
  ],
  description: [
    "OnTheCard was deployed and gained 4500+ users within 6 months",

    "OnTheCard Web App allows users to create and customize their own web page.",
    "The web page created by this application is accessible via user's personal OnTheCard Card and on user's chosen url link. ",
  ],
};

const otcvn = {
  images: [
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTCVN%2F1.png?alt=media&token=17e87b76-36a3-4676-a6ff-ceed6871f058",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTCVN%2F2.png?alt=media&token=92eda870-dc92-4d32-93b0-c15e3a235c34",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTCVN%2F3.png?alt=media&token=ea5bb236-3f0b-4cea-8c4e-96d41918374d",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTCVN%2F4.png?alt=media&token=4c062c8d-381e-4355-acd6-60271d0980e0",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTCVN%2F5.png?alt=media&token=66304a00-0919-4167-a986-f26bb38a3b11",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTCVN%2F6.png?alt=media&token=8792a697-18f5-4459-b303-8f7344d6dc3e",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTCVN%2F7.png?alt=media&token=d0e08fd3-975e-42ee-a24c-93343a3a43a1",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTCVN%2F8.png?alt=media&token=fd817091-3f5b-4a82-a0ae-3fad614144cb",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTCVN%2F9.png?alt=media&token=51414f9b-6f41-40b8-8851-e8e17492649f",
  ],
  description: [
    "This is the Official / E-Commerce Website for OnTheCard, Ltd - A leading Smart Business Card Solution in Vietnam.",
    "The website was developed using HTML5 / CSS3, ES6 , Node-JS and server hosting with AWS EC2 instance.",
  ],
};

const linkshort = {
  images: [
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FLINKSHORT%2FScreen%20Shot%202021-04-02%20at%2011.33.42%20PM.png?alt=media&token=38d1560c-bda2-4f1e-be89-38c36928c267",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FLINKSHORT%2FScreen%20Shot%202021-04-02%20at%2011.34.57%20PM.png?alt=media&token=6dcb2bcb-c40d-4a9f-a71b-4ef42bd4541a",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FLINKSHORT%2FScreen%20Shot%202021-04-02%20at%2011.35.17%20PM.png?alt=media&token=18865f80-895b-444b-964d-c0eae4755616",
  ],
  description: [
    "The web app is a free alternative option to link shortener solution such as bit.ly or cut.ly",
    "This web application allows users to freely shorten their link with 6 different options of domain.",
    "Once shortened, the access link will take less than 2 seconds to redirect to the original link.",
  ],
};

const otcvercel = {
  images: [
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC_VERCEL%2F1.png?alt=media&token=dcfc6052-e5dc-4c64-a4a1-237c39b7e641",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC_VERCEL%2F2.png?alt=media&token=7bd90373-2fb1-4caf-b486-bfdc0dc2add8",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC_VERCEL%2F3.png?alt=media&token=2e23cb09-65a0-4b88-8e07-ca9985bb1125",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC_VERCEL%2F4.png?alt=media&token=5de3792c-bb79-4a81-b85c-532682dad5bb",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC_VERCEL%2F5.png?alt=media&token=d14d51c1-9e8c-49f2-aaf2-d0684a18201d",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC_VERCEL%2F6.png?alt=media&token=d1977851-93ab-4f47-b1e8-72dcc1cb5c48",
  ],
  description: [
    "This website was to be developed as an official and e-commerce website for OnTheCard. However, the website was left unused due to change of theme",
    "This website was an opportunity for me to review and sharpen my Next-Js skill for better SEO and  performance.",
  ],
};

const newroofing = {
  images: [
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FNEWROOFING%2Froof1.png?alt=media&token=0d79f9fc-96e3-4f3b-b5fa-00fe69147af3",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FNEWROOFING%2Froof2.png?alt=media&token=703b2a7b-ac0a-4add-a4c5-81af094f2e7f",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FNEWROOFING%2Froof3.png?alt=media&token=d9765997-506f-4f0a-938c-5e19b793b5cd",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FNEWROOFING%2Froof4.png?alt=media&token=66828ea3-10ed-4640-80f3-d59162d61906",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FNEWROOFING%2Froof5.png?alt=media&token=fbf0aef8-b21d-456f-b7e8-d1698298e28f",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FNEWROOFING%2Froof6.png?alt=media&token=4cd0f6fe-450f-41f9-8963-4bc7162a6963",
  ],
  description: [
    "New Roofing is a freelance website made for a roofing company based in Toronto. Designed and hosted the website with: Bootstrap, MySQL database, PHP and Mail Service on Hostinger",
  ],
};

const xdelivery = {
  images: [
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FXDELIVERY%2FXdeliveryscreen1.png?alt=media&token=8ad968c3-31b3-4992-90ac-8f2d89d2dc55",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FXDELIVERY%2FXdeliveryscreen2.png?alt=media&token=56172267-071b-4fc8-b088-abfc3f4a402c",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FXDELIVERY%2FXdeliveryscreen3.png?alt=media&token=a6a5d4e0-00cb-4f1e-8a20-1d12d5731a88",
  ],
  description: [
    "Xdelivery is a freelance project for a VietNamese company based in Toronto. This project supposed to be a web page for company's delivery service. Unfortunately, the contract was terminated when the project is half-done.",
  ],
};

const prevporfolio = {
  images: [
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FPREVPORTFOLIO%2Fportfolioscreen1.png?alt=media&token=05bc372a-81f2-4590-90b4-c6c7d0b206fa",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FPREVPORTFOLIO%2Fportfolioscreen2.png?alt=media&token=c6ab470e-38f0-4334-8514-1c1c05bdb284",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FPREVPORTFOLIO%2Fportfolioscreen3.png?alt=media&token=4081fec2-00b0-4d04-b62b-8ceab211d3fd",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FPREVPORTFOLIO%2Fportfolioscreen4.png?alt=media&token=5e58f014-121c-4a3d-8806-9c875795add4",
  ],
  description: [
    "Portfolio is a website project where I reviewed and sharpened my HTML5, SASS and JavaScript skills. This is where I introduce about myself, my work experience, projects and contacts.",
  ],
};

const smartmirror = {
  images: [
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FSMART_MIRROR%2FSmartMirrorscreen1.png?alt=media&token=5cde2b4f-8807-4f26-b015-e3e9cdb00541",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FSMART_MIRROR%2FSmartMirrorscreen2.png?alt=media&token=4f69ed22-5352-48bd-80b2-298cd96a1ec3",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FSMART_MIRROR%2FSmartMirrorscreen3.png?alt=media&token=2616ff16-625d-4295-8a99-66c2bfe24974",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FSMART_MIRROR%2FSmartMirrorscreen4.png?alt=media&token=17da3f1b-4e86-4f22-993e-a4365b41d508",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FSMART_MIRROR%2FSmartMirrorscreen5.png?alt=media&token=4d0c31ae-3d58-4116-819f-2166e0129f3c",
    "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FSMART_MIRROR%2FSmartMirrorscreen6.png?alt=media&token=a9c17264-88e6-454c-96dd-a41df5fcc71b",
  ],
  description: [
    "Smart Mirror is an Android Native Application. ",
    "Smart Mirror is used to control a Smart Mirror's microcontroller system via Firebase Real-time database.",
    "The application is developed with Android Studio and Java Programming language.",
  ],
};

export const allProjects = {
  otcme: {
    images: otcme.images,
    projectName: "OnTheCard Customer Web App",
    technology: "React-JS / Redux / Node-JS / MongoDB / AWS",
    description: otcme.description,
    siteUrl: "https://onthecard.me/trungtrinh",
    githubUrl: "",
    video: [
      "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC.ME%2FDEMO%20DESKTOP.mp4?alt=media&token=c268a142-e516-4697-9192-3aa3dae51ee6",
      "https://firebasestorage.googleapis.com/v0/b/trung-portfolio.appspot.com/o/personal%2FProjects%20Image%2FOTC.ME%2FDEMO%20MOBILE.mp4?alt=media&token=3f33b3fc-8de5-4197-a548-495254523275",
    ],
  },
  otcvn: {
    images: otcvn.images,
    projectName: "OnTheCard Official Website",
    technology: "HTML5 / CSS3 / Node-JS / AWS ",
    description: otcvn.description,
    siteUrl: "https://onthecard.vn/",
    githubUrl: "",
    video: [],
  },
  linkshort: {
    images: linkshort.images,
    projectName: "Link Shortener",
    technology: "MERN / Redux / AWS / Firebase / PWA",
    description: linkshort.description,
    siteUrl: "https://linker.live/",
    githubUrl: "https://github.com/Trung28899/MERN-Link-Shortener",
    video: [],
  },
  otcvercel: {
    images: otcvercel.images,
    projectName: "OnTheCard Dark Theme",
    technology: "React-JS / Next-JS / CSS3",
    description: otcvercel.description,
    siteUrl: "https://on-the-card.vercel.app/en-US",
    githubUrl: "",
    video: [],
  },
  newroofing: {
    images: newroofing.images,
    projectName: "NewRoofing Website",
    technology: "Bootstrap 4 / PHP / MySQL",
    description: newroofing.description,
    siteUrl: "http://thenewroofing.ca/",
    githubUrl: "https://github.com/Trung28899/newroofing",
    video: [],
  },
  xdelivery: {
    images: xdelivery.images,
    projectName: "Xdelivery Website",
    technology: "HTML5 / CSS3 / Javascript",
    description: xdelivery.description,
    siteUrl: "https://xdelivery.netlify.app/",
    githubUrl: "https://github.com/Trung28899/Xdelivery",
    video: [],
  },
  prevporfolio: {
    images: prevporfolio.images,
    projectName: "My Previous Portfolio",
    technology: "SASS / JavaScript",
    description: prevporfolio.description,
    siteUrl: "http://trevortrinh.com/trevortrinh/",
    githubUrl: "https://github.com/Trung28899/modern_portfolio",
    video: [],
  },
  smartmirror: {
    images: smartmirror.images,
    projectName: "Smart Mirror Android App",
    technology: "Java / Visual Studio / Firebase",
    description: smartmirror.description,
    siteUrl: "",
    githubUrl: "https://github.com/Trung28899/SmartMirrorApp",
    video: [],
  },
};
